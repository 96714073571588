/*
Purpose: View details of student
Designed: BY R.Sanjai
Date:22/08/2023 
Project: TCMS
*/

import { useNavigate, NavLink } from "react-router-dom";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Stack from 'react-bootstrap/Stack';
import { useState, useEffect, useRef } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import dayjs from 'dayjs';

import 'react-datepicker/dist/react-datepicker.css'; // Import the default styles
import moment from 'moment';

import axios from "axios";
import {
    security_key, options_isholiday, options_days_sessTime, options_holidays
    , BASE_URL
} from "../global";
import Select from 'react-select';


const options_default = [
    { value: '', label: '---Select---' },
    { value: 'Online', label: 'Online' },
    { value: 'In centre', label: 'In centre' },
];





const basicSchema = yup.object().shape({
    //  sest_name: yup.string().required("Session name  is required.."),
    // sest_start_time: yup.string().required("Start time  is required.."),
    // sest_end_time: yup.string().required("End time  is required.."),
    // sest_duration: yup.string().required("Duration  is required.."),
    fk_tut_key: yup.mixed().required("Tutor is required."),
    sest_is_holidays: yup.string().required("Session class is required."),
    sest_type: yup.mixed().required("Session type is required."),
    // sest_from_date: yup
    // .string()
    // .test('is-valid-date', 'Please provide a valid date.', function (value) {
    //   const parsedDate = dayjs(value, 'YYYY-MM-DD').tz(sessionStorage.getItem('time_zone') == '+00:00' ? 'Europe/London' :sessionStorage.getItem('timezone_identifier'));
    //   return parsedDate.isValid();
    // }).required("From date  is required."),
    // //sest_from_date: yup.date().required("From date  is required."),
    // sest_to_date: yup
    // .string()
    // .test('is-valid-date', 'Please provide a valid date.', function (value) {
    //   const parsedDate = dayjs(value, 'YYYY-MM-DD').tz(sessionStorage.getItem('time_zone') == '+00:00' ? 'Europe/London' :sessionStorage.getItem('timezone_identifier'));
    //   return parsedDate.isValid();
    // }).required("To date is required."),
    //sest_to_date: yup.date().required("To date  is required."),
    sest_day: yup.mixed().required("Day is required."),
    sest_from_date: yup
    .string()
    .test('is-valid-date', 'Please provide a valid date.', function (value) {
      const parsedDate = dayjs(value, 'YYYY-MM-DD');
      return parsedDate.isValid();
    }).required("From date  is required."),
    //sest_from_date: yup.date().required("From date  is required."),
    sest_to_date: yup
    .string()
    .test('is-valid-date', 'Please provide a valid date.', function (value) {
      const parsedDate = dayjs(value, 'YYYY-MM-DD');
      return parsedDate.isValid();
    }).required("To date is required."),

    sest_start_time: yup
        .string() // Input value should be a string
        .required("Start time is required.") // Time of contact is required, and an error message is provided if not present
        .test('is-valid-time', 'Please provide a valid time.', function (value) {
          // Check if the value is a valid time
          return dayjs(value, 'HH:mm:ss').isValid();
        }),
    sest_end_time: yup.string()
        .required('End Time  is required.')
        .test('is-valid-time', 'End Time must be after Start Time', function (value) {
            const sest_start_time = moment(this.parent.sest_start_time, 'HH:mm');
            const sest_end_time = moment(value, 'HH:mm');
            return sest_end_time.isAfter(sest_start_time);
        }).test('is-valid-time', 'Please provide a valid time.', function (value) {
            // Check if the value is a valid time
            return dayjs(value, 'HH:mm:ss').isValid();
          }),
    // sest_start_time: yup
    //     .string() // Input value should be a string
    //     .required("Start time is required.") // Time of contact is required, and an error message is provided if not present
    //     .test('is-valid-time', 'Please provide a valid time.', function (value) {
    //       // Check if the value is a valid time
    //       return dayjs(value, 'HH:mm:ss').tz(sessionStorage.getItem('time_zone') == '+00:00' ? 'Europe/London' :sessionStorage.getItem('timezone_identifier')).isValid();
    //     }),
    // //sest_start_time: yup.string().required('Start Time  is required.'),
    // sest_end_time: yup.string()
    //     .required('End Time  is required.')
    //     .test('is-valid-time', 'End Time must be after Start Time', function (value) {
    //         const sest_start_time = moment(this.parent.sest_start_time, 'HH:mm');
    //         const sest_end_time = moment(value, 'HH:mm');
    //         return sest_end_time.isAfter(sest_start_time);
    //     }).test('is-valid-time', 'Please provide a valid time.', function (value) {
    //         // Check if the value is a valid time
    //         return dayjs(value, 'HH:mm:ss').tz(sessionStorage.getItem('time_zone') == '+00:00' ? 'Europe/London' :sessionStorage.getItem('timezone_identifier')).isValid();
    //       }),
    // sest_from_date: yup.date()
    // .required('Start Date  is required..')
    // .nullable(),
    // sest_to_date: yup.date()
    // .when('sest_from_date', (sest_from_date, schema) => {
    //   return sest_from_date
    //     ? schema
    //         .required('End Date  is required..')
    //         .min(sest_from_date, 'End Date must be after Start Date')
    //     : schema;
    // }).nullable(),


    // sest_from_date: yup.date().nullable().required('From Date  is required..'),
    // sest_to_date: yup.date().nullable().required('To Date  is required..').when('sest_from_date', (sest_from_date, schema) => {
    //     return sest_from_date  ? schema.min(sest_from_date, 'To Date must be after From Date') : schema;
    //   }),
    sest_max_student: yup.number().integer().min(1, "Minimum value 1 required").required("Maximum No. of Students is required."),

    
   
   

    

    //sest_skip_holidays: yup.boolean().oneOf([true], "Please check the checkbox").required("Please check the checkbox"),
});



function AddSessionExample() {

    const [isSubmitting, setIsSubmitting] = useState(false);
    const formRef = useRef(null);

    const navigate = useNavigate();

    const handleSave = () => {
        navigate("/list-session-time");
    };
    const [options_tutor, setOptions] = useState([]);

    useEffect(() => {
        // Make an API request to fetch the options
        axios.post(BASE_URL + 'API/api_index.php', {
            security_key: security_key,
            mode: "getAllTutorbycenterSess",
            center_key: sessionStorage.getItem('key')
        })
            .then(response => {
                // Assuming the API response is an array of objects with 'id' and 'value' properties
                const formattedOptions = response.data.data.map(option => ({
                    value: option.pk_tut_key,
                    label: option.tut_fname + ' ' + option.tut_surname
                }));
                setOptions(formattedOptions);
            })
            .catch(error => {
                console.error('Error fetching options from API: ', error);
            });
    }, []);

    const initialValues = {
        // sest_name: '',
        sest_start_time: '',
        sest_end_time: '',
        sest_duration: '',
        sest_from_date: "",
        sest_to_date: "",
        sest_type: '',
        fk_tut_key: '',
        sest_skip_holidays: '',
        sest_is_holidays: '',
        sest_day: "",
        sest_max_student: '',

        fk_cent_key: sessionStorage.getItem('key'),
        created_by: sessionStorage.getItem('key'),

    };


    const formik = useFormik({
        initialValues,
        validationSchema: basicSchema,
        enableReinitialize: true,
        // onSubmit,
        onSubmit: async (formValues) => {

           
            try {
                const response = await axios.post(BASE_URL + "API/api_index.php", {
                    security_key: security_key,
                    mode: "createSessionTime",
                    ...formValues,
                });

                if (response.data.success === true) {
                   
                    if (response.data.data === 1) {
                        toast.success("Success! Your form was submitted", {
                            autoClose: 3000, // 3 seconds in this example
                        });

                        // After 3 seconds, redirect to another page
                        setTimeout(function () {
                            navigate("/list-session-time");
                        }, 3000); // 3000 milliseconds, which is equivalent to 3 seconds

                        setIsSubmitting(false); // Re-enable the submit button
                    } else if (response.data.data == 2) {
                        toast.error("This date, time, or day has already been assigned to tutor. Please choose a different date, time, or day", {
                            autoClose: 3000, // 3 seconds in this example
                        });

                        setIsSubmitting(false); // Re-enable the submit button
                    }
                    else if (response.data.data == 3) {
                        toast.error("The desired day does not exist within the date range.", {
                            autoClose: 3000, // 3 seconds in this example
                        });

                        setIsSubmitting(false); // Re-enable the submit button
                    }else if (response.data.data == 4) {
                        toast.error("Unable to generate session, Please choose a different date or check holidays sessions.", {
                            autoClose: 3000, // 3 seconds in this example
                        });

                        setIsSubmitting(false); // Re-enable the submit button
                    }

                    else {
                        toast.error("Failure! Unable to create session. Try again later", {
                            autoClose: 3000, // 3 seconds in this example
                        });

                        setIsSubmitting(false); // Re-enable the submit button
                    }

                } else {

                    toast.error("Failure! Unable to create session. Try again later", {
                        autoClose: 3000, // 3 seconds in this example
                    });

                    setIsSubmitting(false); // Re-enable the submit button

                }
            } catch (error) {
                console.error("Error fetching data:", error);
                toast.error("Failure! Unable to create session. Try again later", {
                    autoClose: 3000, // 3 seconds in this example
                });

                setIsSubmitting(false); // Re-enable the submit button

            } finally {
                setIsSubmitting(false); // Re-enable the submit button

            }

        },
    });

    // Now, you can set variables as needed:
    const values = formik.values;
    const handleBlur = formik.handleBlur;
    const touched = formik.touched;
    const handleChange = formik.handleChange;
    const errors = formik.errors;
    const handleSubmit = formik.handleSubmit;
    const setTouched = formik.setTouched;
    const resetForm = formik.resetForm;
    const setValues = formik.setValues;
    const handleReset = formik.handleReset;

    const resetFormFields = (resetForm, setValues, initialValues) => {
        resetForm(); // Reset Formik-controlled fields
    };



    useEffect(() => {

        if (!formik.isSubmitting) return;
        if (Object.keys(formik.errors).length > 0) {
            const firstErrorField = Object.keys(formik.errors)[0];
            
            const errorFieldElement = document.getElementById(firstErrorField);

            if (errorFieldElement) {
                errorFieldElement.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                    inline: 'nearest'
                });
            } else {
                // console.error(`Element with ID ${firstErrorField} not found in the DOM.`);
            }
            //document.getElementById(Object.keys(formik.errors)[0]).focus();
        }
    }, [formik]);

  


    return (
        <>
            {/* React alert */}
            <ToastContainer position="top-center" />
            {/* center form */}
            <Form autoComplete="off" onSubmit={handleSubmit} ref={formRef}>

                <div className="">

                    <div className="mb-3 d-flex justify-content-between align-items-center">
                        <div>
                            <h5 className='page-title'> Create  Session Time  </h5>
                            <div role="presentation" >
                                <Breadcrumbs aria-label="breadcrumb" className='my-breadcrumbs'>
                                    <NavLink underline="hover" color="inherit" to='/calendar'> Home</NavLink>


                                    <Typography color="text.primary">Session</Typography>
                                </Breadcrumbs>
                            </div>
                        </div>
                        <div>
                            <Button className="secondary-filled " variant="contained" onClick={() => navigate('/list-session-time')}>
                                Back
                            </Button>
                        </div>
                    </div>


                    <Stack>
                        <div className="my-card p-4 bg-white">
                            <div className='section-heading border-bottom pb-3 mb-3'>
                                <h6 className='mb-0'> Session Time </h6>
                            </div>
                            <Row>

                                {/* --------------sest_ Session Name--------------- */}
                                <Col lg="4" md="6" sm="12" className='position-relative' style={{ display: 'none' }}>
                                    <h6 className="form-lable ">Session Name (eg: TutorLastname Day StartTime)</h6>
                                    <p className="form-control pe-none"><b>#AUTOGENERATE#</b></p>

                                </Col>

                                {/* --------------sest_ Session Name--------------- */}

                                <Col lg="4" md="6" sm="12" className='position-relative mb-3'>
                                    <h6 className="form-lable required-field">Tutor</h6>
                                    <Select menuPlacement="auto" options={options_tutor}
                                        name="fk_tut_key"
                                        id="fk_tut_key"
                                        value={values.fk_tut_key["value"]}
                                        onChange={(selectedOption) => {
                                            const event = {
                                                target: {
                                                    name: "fk_tut_key",
                                                    value: selectedOption["value"],
                                                },
                                            };
                                            handleChange(event);
                                        }}
                                        onBlur={(event) => {
                                            handleBlur(event);
                                            setTouched({ ...touched, fk_tut_key: true });
                                        }}
                                        className={errors.fk_tut_key && touched.fk_tut_key ? "input-error" : ""}
                                    />
                                    {errors.fk_tut_key && touched.fk_tut_key && <span className="error">{errors.fk_tut_key}</span>}
                                </Col>

                                {/* --------------sest_ Session Name--------------- */}

                                <Col lg="4" md="6" sm="12" className='position-relative mb-3'>
                                    <h6 className="form-lable required-field">Session Type</h6>
                                    <Select menuPlacement="auto" options={options_default}
                                        name="sest_type"
                                        id="sest_type"
                                        value={values.sest_type["value"]}
                                        onChange={(selectedOption) => {
                                            const event = {
                                                target: {
                                                    name: "sest_type",
                                                    value: selectedOption["value"],
                                                },
                                            };
                                            handleChange(event);
                                        }}
                                        onBlur={(event) => {
                                            handleBlur(event);
                                            setTouched({ ...touched, sest_type: true });
                                        }}
                                        className={errors.sest_type && touched.sest_type ? "input-error" : ""}
                                    />
                                    {errors.sest_type && touched.sest_type && <span className="error">{errors.sest_type}</span>}
                                </Col>


                                <Col lg="4" md="6" sm="12" className='position-relative mb-3'>
                                    <h6 className="form-lable required-field"> Class</h6>
                                    <Select menuPlacement="auto" options={options_isholiday}
                                        name="sest_is_holidays"
                                        id="sest_is_holidays"
                                        value={values.sest_is_holidays["value"]}
                                        onChange={(selectedOption) => {
                                            const event = {
                                                target: {
                                                    name: "sest_is_holidays",
                                                    value: selectedOption["value"],
                                                },
                                            };
                                            handleChange(event);

                                            if (selectedOption["value"] == 2 && values.sest_from_date != null && values.sest_from_date !== '') {
                                                handleChange({ target: { name: 'sest_to_date', value: values.sest_from_date } });
                                                handleChange({ target: { name: 'sest_skip_holidays', value: '' } });
                                            }else if(selectedOption["value"] == 2){
                                                handleChange({ target: { name: 'sest_skip_holidays', value: '' } });
                                            }
                                        }}
                                        onBlur={(event) => {
                                            handleBlur(event);
                                            setTouched({ ...touched, sest_is_holidays: true });
                                        }}
                                        className={errors.sest_is_holidays && touched.sest_is_holidays ? "input-error" : ""} />
                                    {errors.sest_is_holidays && touched.sest_is_holidays && <span className="error">{errors.sest_is_holidays}</span>}
                                </Col>

                                {/* --------------sest_ Session Name--------------- */}

                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">From Date</h6>
                                    <LocalizationProvider className={'form-control w-100'}  dateAdapter={AdapterDayjs}>
                                        <DatePicker  views={['year', 'month', 'day']}
                                            value={dayjs(values.sest_from_date, 'YYYY-MM-DD')}
                                            //onChange={(date) => }
                                            onChange={(date) =>  {
                                              const event = {
                                              target: {
                                                  name: "sest_from_date",
                                                  value: date != null ? dayjs(date, 'YYYY-MM-DD').format('YYYY-MM-DD') : date,
                                              },
                                              };
                                              //setTouched({ ...touched, sest_from_date: true });
                                            
                                              handleChange(event);
                                              formik.setFieldTouched("sest_from_date", false);
                                              if (values.sest_is_holidays == 2 && date != null && date !== '') {
                                                  handleChange({ target: { name: 'sest_to_date', value: date != null ? dayjs(date, 'YYYY-MM-DD').format('YYYY-MM-DD') : date } });
                                                  formik.setFieldTouched("sest_to_date", false);
                                              }else{
                                                handleChange({ target: { name: 'sest_to_date', value: ''}});
                                                formik.setFieldTouched("sest_to_date", true);
                                              }
                                              
                                          }}
                                            onBlur={(event) => {
                                                handleBlur(event);
                                                setTouched({ ...touched, sest_from_date: true });
                                            }}
                                            name="sest_from_date"
                                            slotProps={{
                                                textField: { readOnly: true, size: 'small',
                                                  id: 'sest_from_date',
                                                },
                                              }}
                                            className={errors.sest_from_date && touched.sest_from_date ? "input-error mb-0 w-100" : "mb-0 w-100"}
                                            //minDate={dayjs()}
                                            format="DD-MM-YYYY"
 />
                                        {errors.sest_from_date && touched.sest_from_date && <span className="error">{errors.sest_from_date}</span>}

                                    </LocalizationProvider>
                                    {/* <LocalizationProvider className={'form-control w-100'}  dateAdapter={AdapterDayjs}>
                                        <DatePicker  views={['year', 'month', 'day']}
                                            value={dayjs(values.sest_from_date, 'YYYY-MM-DD').tz(sessionStorage.getItem('time_zone') == '+00:00' ? 'Europe/London' :sessionStorage.getItem('timezone_identifier'))}
                                            //onChange={(date) => }
                                            onChange={(date) =>  {
                                              const event = {
                                              target: {
                                                  name: "sest_from_date",
                                                  value: date != null ? dayjs(date, 'YYYY-MM-DD').tz(sessionStorage.getItem('time_zone') == '+00:00' ? 'Europe/London' :sessionStorage.getItem('timezone_identifier')).format('YYYY-MM-DD') : date,
                                              },
                                              };
                                              //setTouched({ ...touched, sest_from_date: true });
                                             
                                              handleChange(event);
                                              formik.setFieldTouched("sest_from_date", false);
                                              if (values.sest_is_holidays == 2 && date != null && date !== '') {
                                                  handleChange({ target: { name: 'sest_to_date', value: date != null ? dayjs(date, 'YYYY-MM-DD').tz(sessionStorage.getItem('time_zone') == '+00:00' ? 'Europe/London' :sessionStorage.getItem('timezone_identifier')).format('YYYY-MM-DD') : date } });
                                                  formik.setFieldTouched("sest_to_date", false);
                                              }else{
                                                handleChange({ target: { name: 'sest_to_date', value: ''}});
                                                formik.setFieldTouched("sest_to_date", true);
                                              }

                                             
                                              
                                              
                                          }}
                                            onBlur={(event) => {
                                                handleBlur(event);
                                                setTouched({ ...touched, sest_from_date: true });
                                            }}
                                            name="sest_from_date"
                                            slotProps={{
                                                textField: {
                                                  id: 'sest_from_date',
                                                },
                                              }}
                                            className={errors.sest_from_date && touched.sest_from_date ? "input-error mb-0 w-100" : "mb-0 w-100"}
                                            minDate={dayjs().tz(sessionStorage.getItem('time_zone') == '+00:00' ? 'Europe/London' :sessionStorage.getItem('timezone_identifier'))}
                                            format="DD-MM-YYYY"
                                             />
                                        {errors.sest_from_date && touched.sest_from_date && <span className="error">{errors.sest_from_date}</span>}

                                    </LocalizationProvider> */}

                                    {/* <Form.Control type="date"
                                        value={values.sest_from_date}
                                        //onChange={handleChange}
                                        onBlur={handleBlur}
                                        min={new Date().toISOString().split("T")[0]}

                                       // onChange={handleChange}

                                        onChange={(value) =>  {
                                            const event = {
                                            target: {
                                                name: "sest_from_date",
                                                value: value.target["value"],
                                            },
                                            };
                                           
                                            handleChange(event);
                                            if (values.sest_is_holidays == 2 && value != null && value !== '') {
                                                handleChange({ target: { name: 'sest_to_date', value: value.target["value"] } });
                                            }
                                            
                                        }}
                                        //  defaultValue={new Date()}
                                        id="sest_from_date"
                                        name="sest_from_date"
                                        className={errors.sest_from_date && touched.sest_from_date ? "input-error mb-0" : "mb-0"} />
                                    {errors.sest_from_date && touched.sest_from_date && <span className="error">{errors.sest_from_date}</span>} */}
                                </Col>


                                {/* --------------Session Name--------------- */}

                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">To Date</h6>
                                    <LocalizationProvider className={'form-control'} dateAdapter={AdapterDayjs}>
                                        <DatePicker  views={['year', 'month', 'day']}
                                            value={dayjs(values.sest_to_date, 'YYYY-MM-DD')}
                                            onChange={(date) =>  {
                                              const event = {
                                              target: {
                                                  name: "sest_to_date",
                                                  value: date != null ? dayjs(date, 'YYYY-MM-DD').format('YYYY-MM-DD') : date,
                                              },
                                              };
                                            
                                              handleChange(event);
                                          }}
                                            onBlur={(event) => {
                                                handleBlur(event);
                                                setTouched({ ...touched, sest_to_date: true });
                                            }}
                                           //onBlur={handleBlur, setTouched({ ...touched, sest_day: true });}
                                            name="sest_to_date"
                                            //id="sest_to_date"
                                            slotProps={{
                                                textField: { readOnly: true, size: 'small',
                                                  id: 'sest_to_date',
                                                },
                                              }}
                                            className={errors.sest_to_date && touched.sest_to_date ? "input-error mb-0 w-100" : "mb-0 w-100"}
                                            minDate={values.sest_from_date ? dayjs(values.sest_from_date, 'YYYY-MM-DD') : dayjs()}
                                            
                                            maxDate={ values.sest_is_holidays == 2 && values.sest_from_date ? dayjs(values.sest_from_date, 'YYYY-MM-DD') : null }
                                            //renderInput={(params) => <TextField {...params} />}
                                            format="DD-MM-YYYY"
                                             />
                                        {errors.sest_to_date && touched.sest_to_date && <span className="error">{errors.sest_to_date}</span>}

                                    </LocalizationProvider>

                                    {/* <LocalizationProvider className={'form-control'} dateAdapter={AdapterDayjs} >
                                        <DatePicker  views={['year', 'month', 'day']}
                                            value={dayjs(values.sest_to_date, 'YYYY-MM-DD').tz(sessionStorage.getItem('time_zone') == '+00:00' ? 'Europe/London' :sessionStorage.getItem('timezone_identifier'))}
                                            onChange={(date) =>  {
                                              const event = {
                                              target: {
                                                  name: "sest_to_date",
                                                  value: date != null ? dayjs(date, 'YYYY-MM-DD').tz(sessionStorage.getItem('time_zone') == '+00:00' ? 'Europe/London' :sessionStorage.getItem('timezone_identifier')).format('YYYY-MM-DD') : date,
                                              },
                                              };
                                            
                                              handleChange(event);
                                          }}
                                            onBlur={(event) => {
                                                handleBlur(event);
                                                setTouched({ ...touched, sest_to_date: true });
                                            }}
                                           //onBlur={handleBlur, setTouched({ ...touched, sest_day: true });}
                                            name="sest_to_date"
                                            //id="sest_to_date"
                                            slotProps={{
                                                textField: {
                                                  id: 'sest_to_date',
                                                },
                                              }}
                                            className={errors.sest_to_date && touched.sest_to_date ? "input-error mb-0 w-100" : "mb-0 w-100"}
                                            minDate={values.sest_from_date ? dayjs(values.sest_from_date, 'YYYY-MM-DD').tz(sessionStorage.getItem('time_zone') == '+00:00' ? 'Europe/London' :sessionStorage.getItem('timezone_identifier')) : dayjs().tz(sessionStorage.getItem('time_zone') == '+00:00' ? 'Europe/London' :sessionStorage.getItem('timezone_identifier'))}
                                            
                                            maxDate={ values.sest_is_holidays == 2 && values.sest_from_date ? dayjs(values.sest_from_date, 'YYYY-MM-DD').tz(sessionStorage.getItem('time_zone') == '+00:00' ? 'Europe/London' :sessionStorage.getItem('timezone_identifier')) : null }
                                            //renderInput={(params) => <TextField {...params} />}
                                            format="DD-MM-YYYY"
                                             />
                                        {errors.sest_to_date && touched.sest_to_date && <span className="error">{errors.sest_to_date}</span>}

                                    </LocalizationProvider> */}

                                    {/* <Form.Control type="date" value={values.sest_to_date}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        // min={new Date().toISOString().split("T")[0]}
                                        min={values.sest_from_date ? new Date(values.sest_from_date).toISOString().split("T")[0] : ""}
                                        max={values.sest_is_holidays == 2 && values.sest_from_date ? new Date(values.sest_from_date).toISOString().split("T")[0] : new Date()}

                                        id="sest_to_date"
                                        name="sest_to_date"
                                        className={errors.sest_to_date && touched.sest_to_date ? "input-error mb-0" : "mb-0"} />
                                    {errors.sest_to_date && touched.sest_to_date && <span className="error">{errors.sest_to_date}</span>} */}

                                </Col>

                                {/* --------------sest_ Session Name--------------- */}

                                <Col lg="4" md="6" sm="12" className='mb-3 position-relative'>
                                    <h6 className="form-lable required-field">Day</h6>
                                    <Select menuPlacement="auto" options={options_days_sessTime}
                                        name="sest_day"
                                        id="sest_day"
                                        value={values.sest_day["value"]}
                                        onChange={(selectedOption) => {
                                            const event = {
                                                target: {
                                                    name: "sest_day",
                                                    value: selectedOption["value"],
                                                },
                                            };
                                            handleChange(event);
                                        }}
                                        onBlur={(event) => {
                                            handleBlur(event);
                                            setTouched({ ...touched, sest_day: true });
                                        }}
                                        className={errors.sest_day && touched.sest_day ? "input-error" : ""} />
                                    {errors.sest_day && touched.sest_day && <span className="error">{errors.sest_day}</span>}
                                    {/* <RadioGroup
                                        aria-label="sest_day"
                                        defaultValue="Monday"
                                        overlay
                                        name="sest_day"
                                        sx={{
                                            flexDirection: 'row',
                                            gap: 1,
                                            [`& .${radioClasses.checked}`]: {
                                                [`& .${radioClasses.action}`]: {
                                                    inset: -1,
                                                    border: '3px solid',
                                                    borderColor: 'primary.500',
                                                },
                                            },
                                            [`& .${radioClasses.radio}`]: {
                                                display: 'contents',
                                                '& > svg': {
                                                    zIndex: 2,
                                                    position: 'absolute',
                                                    top: '-8px',
                                                    right: '-8px',
                                                    bgcolor: 'background.surface',
                                                    borderRadius: '50%',
                                                },
                                            },
                                        }}
                                    >

                                        {
                                            Object.entries({
                                                Monday: 'Mon',
                                                Tuesday: 'Tue',
                                                Wednesday: 'Wed',
                                                Thursday: 'Thu',
                                                Friday: 'Fri',
                                                Saturday: 'Sat',
                                                Sunday: 'Sun',
                                            }).map(([key, value]) => (
                                                <Sheet


                                                    key={value}
                                                    variant="outlined"
                                                    sx={{
                                                        borderRadius: 'md',
                                                        boxShadow: 'sm',
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        alignItems: 'center',
                                                        p: 1,
                                                    }}
                                                >
                                                    <Radio

                                                        onChange={(e) => {
                                                            // Get the selected day
                                                            const selectedDay = e.target.value;
                                                            // Update Formik's form state with the selected day
                                                            formik.setFieldValue('sest_day', selectedDay);
                                                        }}
                                                        checked={formik.values.sest_day === key}

                                                        onBlur={handleBlur} value={key} checkedIcon={<CheckCircleRoundedIcon />} />
                                                    <FormLabel htmlFor={value}>{value}</FormLabel>

                                                </Sheet>
                                            ))
                                        }
                                      
                                    </RadioGroup> */}
                                    {/* <div className="d-flex justify-content-around align-items-center">
                                        <div className="text-center  bg-white border rounded py-1 px-2">
                                            <label htmlFor="mon-checkbox">MON</label><br />
                                            <input type="checkbox" className="my_checkbox" name="sest_day" onChange={handleChange} onBlur={handleBlur} value={'Monday'} />
                                        </div>
                                        <div className="text-center  bg-white border rounded py-1 px-2">
                                            <label htmlFor="mon-checkbox">TUE</label><br />
                                            <input type="checkbox" className="my_checkbox" name="sest_day" onChange={handleChange} onBlur={handleBlur} value={'Tuesday'} />
                                        </div>
                                        <div className="text-center  bg-white border rounded py-1 px-2">
                                            <label htmlFor="mon-checkbox">WED</label><br />
                                            <input type="checkbox" className="my_checkbox" name="sest_day" onChange={handleChange} onBlur={handleBlur} value={'Wednesday'} />
                                        </div>
                                        <div className="text-center  bg-white border rounded py-1 px-2">
                                            <label htmlFor="mon-checkbox">THU</label><br />
                                            <input type="checkbox" className="my_checkbox" name="sest_day" onChange={handleChange} onBlur={handleBlur} value={'Thursday'} />
                                        </div>
                                        <div className="text-center  bg-white border rounded py-1 px-2">
                                            <label htmlFor="mon-checkbox">FRI</label><br />
                                            <input type="checkbox" className="my_checkbox" name="sest_day" onChange={handleChange} onBlur={handleBlur} value={'Friday'} />
                                        </div>
                                        <div className="text-center  bg-white border rounded py-1 px-2">
                                            <label htmlFor="mon-checkbox">SAT</label><br />
                                            <input type="checkbox" className="my_checkbox" name="sest_day" onChange={handleChange} onBlur={handleBlur} value={'Saturday'} />
                                        </div>
                                        <div className="text-center  bg-white border rounded py-1 px-2">
                                            <label htmlFor="mon-checkbox">SUN</label><br />
                                            <input type="checkbox" className="my_checkbox" name="sest_day" onChange={handleChange} onBlur={handleBlur} value={'Sunday'} />
                                        </div>

                                    </div> */}

                                </Col>


                                {/* -------------- Start Time--------------- */}

                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">Start Time</h6>
                                    {/* <Form.Control type="time" value={values.sest_start_time}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="sest_start_time"
                                        className={errors.sest_start_time && touched.sest_start_time ? "input-error mb-0" : ""}
                                    /> */}

<LocalizationProvider className={'form-control'}  dateAdapter={AdapterDayjs}>
                                 
                                 <TimePicker 
                                 value={dayjs(values.sest_start_time, 'HH:mm:ss')}
                                 onChange={(time) =>  {
                                 const event = {
                                 target: {
                                     name: "sest_start_time",
                                     value: time!=null ? dayjs(time, 'HH:mm:ss').format('HH:mm:ss') : time,
                                 },
                                 };
                                 
                                 handleChange(event);
                                 }}
                                 onBlur={(event) => {
                                     handleBlur(event);
                                     setTouched({ ...touched, sest_start_time: true });
                                 }}
                                 //id="sest_start_time"
                                 slotProps={{
                                     textField: { readOnly: true, size: 'small',
                                     id: 'sest_start_time',
                                     },
                                 }}
                                 className={errors.sest_start_time && touched.sest_start_time ? "input-error w-100 h-auto" : "w-100 h-auto"}
                                 />
                             {errors.sest_start_time && touched.sest_start_time && <span className="error">{errors.sest_start_time}</span>}
                             </LocalizationProvider>
                                    
                                </Col>
                                {/* --------------End Time--------------- */}

                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">End Time</h6>
                                    {/* <Form.Control type="time" value={values.sest_end_time}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="sest_end_time"
                                        className={errors.sest_end_time && touched.sest_end_time ? "input-error mb-0" : ""}
                                    /> */}

                                    
                            {/* <LocalizationProvider className={'form-control'}  dateAdapter={AdapterDayjs}>
                                 
                                 <TimePicker 
                                  value={dayjs(values.sest_end_time, 'HH:mm:ss').tz(sessionStorage.getItem('time_zone') == '+00:00' ? 'Europe/London' :sessionStorage.getItem('timezone_identifier'))}
                                  onChange={(time) =>  {
                                   const event = {
                                   target: {
                                       name: "sest_end_time",
                                       value: time != null ? dayjs(time, 'HH:mm:ss').tz(sessionStorage.getItem('time_zone') == '+00:00' ? 'Europe/London' :sessionStorage.getItem('timezone_identifier')).format('HH:mm:ss') : time,
                                   },
                                   };
                                  
                                   handleChange(event);
                                   
                                   
                               }}
                                 // onChange={handleChange}
                                 onBlur={(event) => {
                                    handleBlur(event);
                                    setTouched({ ...touched, sest_end_time: true });
                                }}
                                 // id="sest_end_time"
                                 slotProps={{
                                    textField: {
                                      id: 'sest_end_time',
                                    },
                                  }}
                                  className={errors.sest_end_time && touched.sest_end_time ? "input-error  w-100 h-auto" : "w-100 h-auto"}
                                 />
                              {errors.sest_end_time && touched.sest_end_time && <span className="error">{errors.sest_end_time}</span>}
                             </LocalizationProvider> */}

<LocalizationProvider className={'form-control'}  dateAdapter={AdapterDayjs}>
                                        
                                        <TimePicker 
                                        value={dayjs(values.sest_end_time, 'HH:mm:ss')}
                                        onChange={(time) =>  {
                                        const event = {
                                        target: {
                                            name: "sest_end_time",
                                            value: time!=null ? dayjs(time, 'HH:mm:ss').format('HH:mm:ss') : time,
                                        },
                                        };
                                        
                                        handleChange(event);
                                         
                                         }}
                                        // onChange={handleChange}
                                        onBlur={(event) => {
                                            handleBlur(event);
                                            setTouched({ ...touched, sest_end_time: true });
                                        }}
                                        // id="sest_end_time"
                                        slotProps={{
                                            textField: { readOnly: true, size: 'small',
                                            id: 'sest_end_time',
                                            },
                                        }}
                                        className={errors.sest_end_time && touched.sest_end_time ? "input-error  w-100 h-auto" : "w-100 h-auto"}
                                        />
                                    {errors.sest_end_time && touched.sest_end_time && <span className="error">{errors.sest_end_time}</span>}
                                    </LocalizationProvider>
                                  
                                </Col>



                                {/* --------------Duration--------------- */}

                                {/* <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">Duration</h6>
                                     <Select menuPlacement="auto"  options={options_duration}
                                        name="sest_duration"
                                        id="sest_duration"
                                        value={values.sest_duration["value"]}
                                        onChange={(selectedOption) => {
                                            const event = {
                                                target: {
                                                    name: "sest_duration",
                                                    value: selectedOption["value"],
                                                },
                                            };
                                            handleChange(event);
                                        }}
                                        onBlur={(event) => {
                                            handleBlur(event);
                                            setTouched({ ...touched, sest_duration: true });
                                        }}
                                        className={errors.sest_duration && touched.sest_duration ? "input-error" : ""} />
                                    {errors.sest_duration && touched.sest_duration && <p className="error">{errors.sest_duration}</p>}
                                </Col>
 */}

                                {/* <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">Duration</h6>
                                    <Form.Control type="time"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="sest_duration"
                                        value={moment.duration((values.sest_start_time).diff(values.sest_end_time))}
                                        className={errors.sest_duration && touched.sest_duration ? "input-error" : ""}
                                    />
                                    {errors.sest_duration && touched.sest_duration && <p className="error">{errors.sest_duration}</p>}
                                </Col> */}
                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">Duration</h6>
                                    <Form.Control
                                        type="text" // Use "text" type for displaying duration
                                        id="sest_duration"
                                        
                                        value={values.sest_end_time && values.sest_start_time && values.sest_end_time!='Invalid Date' && values.sest_start_time !='Invalid Date' ?
                                            moment
                                                .utc(
                                                    moment(values.sest_end_time, 'HH:mm').diff(moment(values.sest_start_time, 'HH:mm')
                                                    )
                                                )
                                                .format('HH:mm') // Format the duration as HH:mm
                                            : '00.00'
                                        }
                                        readOnly // Make the input field read-only
                                        className={errors.sest_duration && touched.sest_duration ? "input-error" : ""}
                                    />
                                    {errors.sest_duration && touched.sest_duration && <p className="error">{errors.sest_duration}</p>}
                                </Col>

                                <Col lg="4" md="6" sm="12" className='mb-3 position-relative'  style={{ display: values.sest_is_holidays == 2 ? 'none' : 'block' }}>
                                    <h6 className="form-lable">Skip Holidays</h6>
                                    <Select menuPlacement="auto" options={options_holidays}
                                        value={options_holidays.find(option => option.value === values.sest_skip_holidays)}

                                        name="sest_skip_holidays"
                                        id="sest_skip_holidays"

                                        onChange={(selectedOption) => {
                                            const event = {
                                                target: {
                                                    name: "sest_skip_holidays",
                                                    value: selectedOption["value"],
                                                },
                                            };
                                            handleChange(event);
                                        }}
                                        onBlur={(event) => {
                                            handleBlur(event);
                                            setTouched({ ...touched, sest_skip_holidays: true });
                                        }}
                                        className={errors.sest_skip_holidays && touched.sest_skip_holidays ? "input-error" : ""} />
                                    {errors.sest_skip_holidays && touched.sest_skip_holidays && <p className="error">{errors.sest_skip_holidays}</p>}
                                </Col>

                                {/* --------------Student cout--------------- */}

                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">Maximum No. of Students</h6>
                                    <Form.Control type="number"
                                        value={values.sest_max_student}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="sest_max_student"
                                        min="1"
                                        className={errors.sest_max_student && touched.sest_max_student ? "input-error" : ""}
                                    />
                                    {errors.sest_max_student && touched.sest_max_student && <p className="error">{errors.sest_max_student}</p>}
                                </Col>

                                {/* <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field"></h6>

                                    <Form.Check
                                        type="checkbox"
                                        label="Skip Holidays"
                                        name="sest_skip_holidays"
                                        id="sest_skip_holidays"
                                        checked={values.sest_skip_holidays}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className='mt-4'
                                    />
                                </Col> */}


                            </Row>
                        </div>
                    </Stack>

                    {/* --------------Session Time End--------------- */}

                    <Stack gap={2} className="mt-4">
                        <div className="text-end">

                            <Button variant="contained" className="primary-gray me-2" onClick={handleSave}>Cancel</Button>
                            <Button type="submit" variant="contained" className="primary-filled " disabled={isSubmitting}>Save</Button>
                        </div>
                    </Stack>



                </div>

            </Form>
        </>
    );
}

export default AddSessionExample;